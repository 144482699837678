@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

ul {
  list-style: none;
  padding: 0;
}
.footer {
  // background-color: rgba(244, 246, 248,.74);
  // background-color: #F4F8FD;
  // background-color: #131c21;
  // background-color:  #B555C3;
  background-color: #d8ca67;
  background-image: linear-gradient(90deg, #a32bb4 0%, #854cd2 90%);

  padding: 72px 0 0 0;

  .footer-top {
    border-bottom: 2px solid #000;
    padding-bottom: 2.8rem;
  }

  .footer-bottom {
    padding: 1rem 0;
    background-color: #000;

    p {
      margin: 0;
      color: #ffffff;
    }
  }
  a {
    padding: 0.5rem 0;
  }
}
.footer-col {
  @media #{$xs-layout} {
    margin-bottom: 1rem;
  }
  .main-heading {
    color: $theme-hover-color;
    margin-bottom: 1.8rem;
    text-decoration: none;
    font-size: 1.3rem;
    line-height: 15px;
    font-weight: 600;
  }
  p {
    margin-top: 1rem;
    color: #fff;
  }

  h4 {
    margin: 0px 0px 25px 0px;
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 0px;
  }

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 0;
      }
      a {
        color: #fff;
        &:hover {
          color: $white;
          padding-left: 2px;
        }
      }
      a,
      p {
        text-transform: capitalize;
        text-decoration: none;
        padding: 0.5rem 0;
        display: block;
        transition: all 0.3s ease;
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        font-style: normal;
      }
    }
  }

  .social-links {
    a {
      display: inline;
      font-size: 1.2rem;
      //   background-color: $theme-color;
      margin: 0 10px 10px 0;
      text-align: center;
      line-height: 40px;
      //   border-radius: 50%;
      color: #ffffff;
      transition: all 0.5s ease;
      //   border: 2px solid $theme-color;
      padding: 10px;

      &:hover {
        color: #ffffff;
        font-size: 1.3rem;
      }
    }
  }
}

.download-btn-group-footer {
  display: flex;
  gap: 16px;
  // align-items: center;
  flex-direction: column;
  margin: 1rem 0;

  @media #{$xs-layout} {
    align-items: flex-start;
    gap: 10px;
  }

  .banner-btn {
    width: fit-content;
    // padding: 8px 16px 8px 8px;
    // border-radius: 10px;
    padding: 0;
    cursor: pointer;
    color: #ffffff;
    background-color: transparent;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.08);
      box-shadow: 1.91px 9.833px 15px 0px rgba(38, 38, 38, 0.15);
    }

    .market-img-div {
      width: 100%;
      // margin-right: 10px;

      .play-store {
        width: 175px;
        height: 58px;
      }

      .app-store {
        width: 175px;
        height: 58px;
      }
    }

    .market-content-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 60%;

      p {
        margin-bottom: 0;
      }

      .tagline {
        margin-bottom: 0;
        color: #854cd2;
        font-weight: 500;
      }
      .headline {
        font-weight: 600;
        color: #333;
        font-size: 1rem;
      }
    }
  }
}

.contact-box {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin: 0.5rem 0;

  &:not(:nth-child(1)) {
    align-items: center;
  }

  .finkids-img {
    // background-color: #fff;
    // padding: 15;
    // border-radius: 15;
    // border: 1px solid red;
    width: 190px;
    height: 90px;
  }

  .contact-icon {
    color: $theme-color;
    font-size: 0.8rem;
    border: 1px solid $theme-color;
    border-radius: 100%;
    padding: 0.5rem;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 20px;
    transition: all 0.1s;
  }

  .contact-details {
    width: 90%;
    margin-bottom: 0.1rem;

    .cin-no{
      font-size: 13px;
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
      color: #fff;
    }

    a {
      margin-bottom: 0;
      color: #fff;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
