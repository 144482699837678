.slider {
  padding: 0;
  height: 700px;
  overflow: hidden;
  margin-top: 5rem;

  .col-12 {
    padding: 0;
  }

  img {
    width: 100%;
    height: 600px;
  }

  a {
    padding: 0;
  }
}

.slick-slider {
  position: relative;

  // .slick-arrow{
  //   display: none;
  // }

  .slick-prev, .slick-next{
    display: none !important;
  }
  .slick-dots {
    position: absolute;
    bottom: 1rem;

    li {
      &:active {
        button {
          border-color: #ff0055;
        }
      }
      button {
        width: 20px;
        height: 20px;
        font-size: 1rem;

        &:before {
          font-family: "slick";
          font-size: 19px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          content: "•";
          text-align: center;
          opacity: 0.25;
          border: 1px solid $theme-color;
          border-radius: 50%;
          padding: 0.4rem;
          color: $white;
        }
      }
    }
  }
}

// .slick-dots li.slick-active button:before {
//   opacity: 0.75;
//   color: $theme-color;
// }

// @media only screen and (max-width: 768px) {
//   .carousel .slick-dots {
//     bottom: -9.5vw;
//   }
// }
// .carousel .slick-dots li button {
//   width: 20px;
//   height: 20px;
//   border: 1px solid #333;
//   border-radius: 20px;
// }
// .carousel .slick-dots li button:before {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 10px;
//   height: 10px;
//   border-radius: 10px;
//   content: "";
//   text-align: center;
//   opacity: 0.5;
//   color: #000;
//   background-color: #333;
//   transform: translate(-50%, -50%);
//   transition: all 0.3s ease-in-out;
// }
// .carousel .slick-dots li.slick-active button,
// .carousel .slick-dots li:hover button {
//   border-color: #ff0055;
// }
// .carousel .slick-dots li.slick-active button:before,
// .carousel .slick-dots li:hover button:before {
//   background-color: #ff0055;
// }
// .carousel .slick-prev,
// .carousel .slick-next {
//   height: 50px;
//   width: 50px;
//   border-radius: 7px;
//   background-color: #fff;
// }
// .carousel .slick-prev:hover,
// .carousel .slick-prev:focus,
// .carousel .slick-next:hover,
// .carousel .slick-next:focus {
//   color: #fff;
//   outline: none;
//   background: #fff;
// }
// .carousel .slick-prev:before,
// .carousel .slick-next:before {
//   color: #000;
// }
// .carousel .slick-next {
//   right: -55px;
// }
// .carousel .slick-prev {
//   left: -55px;
// }
