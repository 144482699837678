.about-app {
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.38),
      rgba(68, 68, 68, 0.38)
    ),
    url("../images/ggp/app-banner.png");

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.dashboard-section {
  .dashboard-wrapper {
    .nav-tabs {
      border: none;
      margin-bottom: 2rem;

      .nav-item {
        height: auto;
        border: none;

        @media #{$lg-med-layout} {
          width: auto;
        }

        .nav-link {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 38px;
          color: #fab187;
          border: none !important;
          margin-bottom: 0px;
          border-bottom: 2px solid rgba(250, 177, 135, 0.7) !important;
          border-radius: 0px !important;
          position: relative;
          padding: 12px 25px;

          &.active {
            color: #fba823;
            background-color: transparent !important;
            border: none !important;

            &:after {
              position: absolute;
              content: "";
              height: 6px;
              background: #fba823;
              width: 100%;
              bottom: -3px;
              left: 0px;
              border-radius: 5px;
            }
          }
        }
      }
    }
    .dashboard-content {
      .parent-item {
        margin-bottom: 12px;
        padding: 5px;

        h4 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          background: linear-gradient(90deg, #a32bb4 -4.7%, #854cd2 75.33%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 8px;
        }
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #444444;
          margin-bottom: 0;
        }
      }
    }
  }
}

.gold-app-features {
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.28),
      rgba(68, 68, 68, 0.28)
    ),
    url(../images/kidbanner.png);
  background-size: cover;
  background-position: center;
  padding: 5rem 0;

  .feature-desc-head{
    color: #000; // #fba823;
    text-shadow: 1px 1px 3px #fff;
    font-weight: 600;
  }

  .feature-card {
    border: 2px solid rgb(250, 248, 149);
    border-radius: 10px;
    padding: 16px;
    background-color: #ffffffa6;
    height: 100%;
    box-shadow: 0 0 11.83px 1.17px rgba(0, 0, 0, 0.06);

    .app-feature-image {
      width: 100px;
    }
    .feature-name {
      font-size: 1.5rem;
      line-height: 1.95rem;
    }
    .feature-desc {
      font-size: 1.1rem;
      font-weight: 500;
      color: #333;
    }
  }
}

.faqs-section {
  .faqs-wrapper {
    .nav-tabs {
      border: none;
      margin-bottom: 2rem;

      .nav-item {
        height: auto;
        border: none;

        @media #{$lg-med-layout} {
          width: auto;
        }

        .nav-link {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 28px;
          color: #fab187;
          border: none !important;
          margin-bottom: 0px;
          border-bottom: 2px solid rgba(250, 177, 135, 0.7) !important;
          border-radius: 0px !important;
          position: relative;
          padding: 10px 18px;

          &.active {
            color: #fba823;
            background-color: transparent !important;
            border: none !important;

            &:after {
              position: absolute;
              content: "";
              height: 6px;
              background: #fba823;
              width: 100%;
              bottom: -3px;
              left: 0px;
              border-radius: 5px;
            }
          }
        }
      }
    }
    .faqs-content {
      .parent-item {
        margin-bottom: 12px;
        padding: 5px;

        h4 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          background: linear-gradient(90deg, #a32bb4 -4.7%, #854cd2 75.33%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 8px;
        }
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #444444;
          margin-bottom: 0;
        }
      }
    }
  }
}
