.topbar{
    padding: 7px 0;
    border-bottom: 1px solid #efefef;
    background-color: #D8CA67;
    background-image: linear-gradient(90deg, #A32BB4 0%, #854CD2 100%);

    .topbar-content{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$xs-layout}{
            justify-content: center;
        }
    }

    .contact__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media #{$xs-med-layout}{
            justify-content: center;
        }

        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 .5rem;

            @media #{$xs-med-layout}{
                margin-bottom: .25rem;
            }

            .topbar-icon{
                color: #fff;
            }

            a{
                padding: 0 .35rem;
                font-weight: 400;
                // font-family: $font-secondary;
                color: #fff;

                &:hover{
                    color: $theme-color;
                }
            }

            
        }
    }

    .social-container{

        @media #{$xs-layout}{
            display: none;
        }
    }

    .social-icons{
        display: flex;
        justify-content: space-between;
        align-items: center;

        li{
            a{
                padding: .25rem;
                height: 28px;
                width: 28px;
                margin: 0 .25rem;
                border-radius: 50%;
                background-color: $black;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .3s ease;

                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
}