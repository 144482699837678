.about_wrapper {
  // background-image: url("../images/mapdots.png");
  // background-image: url("../images/ggp/doodle.webp");
  // background: rgba(98, 77, 214, .5);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  .sub-text {
    font-weight: 700;
    color: $theme-color;
  }

  .service-box {
    // border: 1px solid $theme-color;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    margin: 0.5rem;
    box-shadow: 0 1px 2px rgb(0 0 0 / 25%), 0 -1px 1px rgb(0 0 0 / 13%);
    transition: all 0.4s ease-in-out;

    @media #{$xs-med-layout} {
      margin: 0.5rem auto;
    }

    .about-card-heading {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 0.5rem;

      .about-card-img {
        width: 100px;
        height: 100px;
      }

      h2 {
        font-size: 23px;
        color: #a32bb4;
        letter-spacing: 0.1rem;
        line-height: 1.5rem;
        margin-bottom: 0;

        @media #{$xs-layout} {
          font-size: 18px;
        }
      }
    }

    h6 {
      color: $theme-color;
      transition: all 0.3s ease-in-out;
      // font-size: 1.1rem;
      // font-family: Cookies;
    }
    span {
      font-size: 1.7rem;
      margin: 0 0.5rem 0 0;
      color: $theme-hover-color;

      .about-img {
        width: 100px;
        height: 100px;
      }
    }

    &:hover {
      transform: scale(1.05);

      h6 {
        color: $theme-hover-color;
      }
    }
  }
}

.about {
  background-color: #f8fbfd;
  padding: 2rem;
}

.about-content {
  padding: 1rem;
  width: 50%;
}

// .about-page {
//   padding: 2rem 0;
//   height: 100%;
// }

//   about page ///////////////////////////////////////////////////
.about-section,
.join-section {
  margin-bottom: 4rem;
}

.about-text,
.join-text {
  width: 100%;
  padding: 1rem;
}

.about-image img {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .about-quality {
    grid-template-columns: auto;
  }
}

.about-quality p {
  color: #666;
}

// .about-text-para p{
//     font-size: 1.1rem;
//     font-family: Poppins;
//     color: #444;
// }

@media only screen and (max-width: 768px) {
  // .about-page {
  //   padding: 1rem 0;
  // }
  .about-section,
  .join-section {
    padding: 0 1.5rem;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 2rem;
  }

  .about-image {
    display: none;
  }
  .about-text-para p {
    font-size: 1rem;
  }
}

// about join ///////////////////////////////////////////

.about-join {
  background: #f4f6f8;
  padding: 2rem 0;

  .join-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .img-div {
    width: 180px;
    height: 180px;
  }

  h4 {
    margin-top: 2rem;
    font-size: 25px;
    line-height: 1.2;
    font-family: "Poppins";
    font-weight: 700;
    color: #131c21;
    font-style: normal;
  }
}
.join-head {
  text-align: center;
  margin-bottom: 4rem;
}

.join-content {
  text-align: center;
  /* background: #777; */
  // padding: 1rem;
}

.join-content-1 .about-icon {
  font-size: 7rem;
  border: 3px solid rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  padding: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .about-join {
    padding: 1rem;
  }
  .join-content {
    flex-direction: column;
  }
}
