.features_wrapper {
  background-color: #f3f3f3;

  .card-body {
    @media #{$xs-layout} {
      text-align: left !important;
    }
  }

  // .feature-img{
  //   @media #{$xs-layout} {
  //     display: none;
  //   }
  // }

  .features {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem;
    margin: 0.8rem 0;
    overflow-x: hidden;

    .feature-icon {
      margin: 0.3rem;
      span {
        .icons {
          // background: rgba(153, 153, 153, 0.4);
          // padding: 1rem;
          
          height: 70px;
          width: 70px;
          // border-radius: 50%;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .feature-text {
      margin: 0.3rem;
      h3 {
        font-size: 19px;
        line-height: 12px;
        margin-bottom: 12px;
      }
      p {
        margin-bottom: 0.1rem;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  h3 {
    span {
      color: $theme-color;
    }
  }
}
