.navbarContainer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  background: transparent;

  &.active {
    background: #fff;
    border-bottom: 1px solid #e9e9e9;
  }

  @media #{$lg-med-layout} {
    padding-left: 0;
  }
}

.navlogo {
  color: $theme-color;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  // align-items: center;
}

.mobile-icon {
  display: none;

  @media #{$lg-md-layout} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media #{$lg-med-layout} {
    display: flex;
    flex-direction: column;
    width: 50%;
    z-index: 90;
    height: 100vh;
    position: absolute;
    transform: translateX(-120%);
    opacity: 1;
    transition: all 0.7s ease;
    background: #fff;

    &.active {
      transform: translateX(0);
    }
  }
}

.nav-menu:active {
  left: 0;
}

.nav-item {
  height: 80px;
  border: 2px solid transparent;
}

@media screen and (max-width: 960px) {
  .nav-item {
    width: 100%;
  }
  .nav-item:hover {
    border: none;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  .submenu-heading {
    .dropdown-panel {
      position: absolute;
      top: 60px;
      left: 15%;
      background: #fff;
      width: 100%;
      max-width: 750px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      padding: 15px;
      border: 1px solid #ededed;
      box-shadow: 0 3px 5px #0000001a;
      border-radius: 4px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all 0.5s ease;
      z-index: 500;

      @media #{$lg-layout-1500} {
        left: 10%;
        transform: translateX(150px);
      }

      @media #{$lg-md-layout} {
        left: 1%;
        transform: translateX(25px);
      }

      .dropdown-panel-list {
        li {
          padding: 10px;
          margin: 0;

          a {
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:hover {
              transform: translateX(15px);
              transition: all 0.35s ease;

              .submenu-div1 {
                .submenu-icon {
                  color: $theme-hover-color;
                }
              }
              .submenu-div2 {
                h5 {
                  color: $theme-hover-color;
                }
                p {
                  color: #333;
                }
              }
            }

            .submenu-div1 {
              .submenu-icon {
                width: 50px;
                height: 50px;
                color: #857e7e;
                margin-right: 0.79rem;
              }
            }

            .submenu-div2 {
              h5 {
                text-decoration: none;
                background: none !important;
                display: inline-block;
                width: 100%;
                color: #333;
                font-weight: 500;
                font-size: 17px;
                margin-bottom: 0;
              }
              p {
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                margin-top: 5px;
                color: #676767;
              }
            }
          }
        }
      }
    }

    &:hover {
      .dropdown-panel {
        transform: translateX(250px);
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: all 0.5s ease;

        @media #{$lg-md-layout} {
          left: 5%;
          transform: translateX(125px);
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #4b59f7;
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: 960px) {
  .nav__item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}

.nav__btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
}
