.form-control {
    margin: 1rem 0;
  }

  .form-control label,
  .form-control input,
  .form-control textarea {
    display: block;
  }

  .form-control textarea{
    resize: none;
  }
  
  .form-control label {
    font-weight: bold;
    margin-bottom: 1.2rem;
  }
  
  .form-control input,
  .form-control textarea {
    width: 100%;
    font: inherit;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: .8rem .8rem;
  }
  
  .form-control input:focus,
  .form-control textarea:focus {
    outline: none;
    background: #ebebeb;
    border-color: #510077;
  }
  
  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }
  
  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffd1d1;
  }

  .form-inputs{
    position: relative;
  }