.hardware {
    .hardware-image{
      width: 100%;
      height: 400px;
      overflow: hidden;
      
        .img-fluid{
            border-radius: 8px;
        }
    }
  .hardware-content {
    padding: 5px 20px;

    .hardware-name {
      font-size: 1.7rem;
      font-weight: 500;
      margin-bottom: 0.1rem;
    }
    .hardware-range {
      font-size: 1.1rem;
      font-weight: 600;
      text-transform: capitalize;
      color: $theme-hover-color;
      margin: 0.5rem 0;
    }
    .hardware-usage {
      margin: 0.5rem 0;
    }
    .hardware-type {
      font-weight: 600;
    }
    .hardware-usage {
      font-weight: 600;
      color: #777;
    }

    .hardware-benefit {
      margin: 5px 0 10px;
      padding: 0;
      border-radius: 5px 5px 0 0;
      overflow: hidden;
      border-bottom: 0;
      margin-top: 1.5rem;

      .hardware-features {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        color: #fff;
        // text-align: center;
        padding: 15px;
        text-transform: capitalize;
        background-image: linear-gradient(0deg, #1e3c72 0, #1e3c72 1%, #2a5298);
      }

      ul {
        padding: 20px;
        padding: 1rem;
        border: 1px solid #f6f6f8;
        border-radius: 0 0 10px 10px;
        border-top: 0;
      }

      li {
        font-weight: 500;
        position: relative;
        padding-left: 1.3em;
        margin: 0 0 5px;
        color: #333333da;

        &:before {
          position: absolute;
          height: 20px;
          width: 20px;
          left: 0;
          top: 0;
          content: "\f105";
          font-family: FontAwesome;
          font-size: 20px;
          color: $theme-hover-color;
        }
      }
    }
  }
}

.hardware-tabs {
  .nav-tabs {
    margin: 0 !important;

    .nav-item {
      border: none;
      height: fit-content;
    }

    .nav-link {
      color: #495057;
    //   font-weight: 500;
      padding: 12px 25px;
      font-size: 1.2rem;
      letter-spacing: 1.1px;
      background-color: #cccccc;
      border-color: #dee2e6 #dee2e6 #fff;
      text-transform: capitalize;
      border-radius: 0;

      &.active {
        background-color: rgba(19, 28, 33,.9);
        color: #fff;
      }
    }
  }
  .tab-content{
      border: 1px solid #ccc;
      border-radius: 0 0 8px 8px;
      padding: 20px;

      p{
        font-size: 1.1rem;
        letter-spacing: 1px;
        line-height: 1.5;
      }
  }
}
