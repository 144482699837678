.App {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.card {
    width: auto;
    color: #fff;
    padding: 5%;
}


.cardtop {
    width: 100%;
    display: table-cell;
    height: 130px;
    vertical-align: middle;
    text-align: center;
}

.cardtop>img {
    margin: 5%;
    width: 90%;
}

@media (max-width: 800px) {
    .card {
        padding: 10% 8%;
    }

    .cardtop {
        display: inline;
        justify-content: center;
    }

}

@media (min-width: 2000px) {
    .App {
        width: 50%;
    }

    .card {
        padding: 10% 8%;
    }

    .cardtop {
        width: 100%;
        display: table-cell;
        height: 130px;
        vertical-align: middle;
        text-align: center;
        /* display: inline;
        justify-content: center; */
    }

}