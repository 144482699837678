.custom-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    cursor: pointer;

    &:hover {
        color: $theme-color;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__checkmark {
        margin-right: 10px;
        height: 15px;
        width: 15px;
        border: 1px solid $theme-hover-color;

        i {
            transform: scale(0);
            transition: transform 0.3s ease;
        }
    }

    input:checked ~ &__checkmark {
        background-color: $theme-color;
        border: 1px solid $theme-color;
        color: $white;
    }

    input:checked ~ &__checkmark > i {
        transform: scale(1);
    }
}
