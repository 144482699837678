.side-drawer{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 50%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .26);

    @media #{$xs-med-layout}{
        width: 70%;
    }
}

.side-drawer{
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    max-width: 320px;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
    overscroll-behavior: contain;
    transition: 0.5s ease;
    background: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
}