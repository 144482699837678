.banner {
  padding: 75px 0;
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.38),
      rgba(68, 68, 68, 0.38)
    ),
    url("../images/ggp/homebg.png");
  // background-color: transparent;
  // background-image: linear-gradient(180deg, #AA23AD 0%, #785BDF 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;

  @media #{$lg-md-layout} {
    padding: 35px 0;
  }

  .banner-left {
    .banner-heading {
      font-size: 33px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 20px;
      line-height: 50px;
      font-family: cookies;

      span{
        color: $theme-color;
        font-family: cookies;
        font-size: 45px;
        text-shadow: 1px 1px 4px #FFFFFF;

        @media #{$xs-layout} {
          font-size: 32px;
          line-height: 33px;
        }
      }

      @media #{$xs-layout} {
        font-size: 22px;
        line-height: 33px;
      }
    }

    .banner-text {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      font-style: normal;
      font-weight: 400;
    }
    @media #{$xs-layout} {
      font-size: 10px !important;
      line-height: 24px;
    }
    .download-btn-group {
      display: flex;
      gap: 16px;
      align-items: center;
      margin: 1rem 0;

      @media #{$xs-layout}{
        // flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }

      .banner-btn {
        width: fit-content;
        // padding: 8px 16px 8px 8px;
        // border-radius: 10px;
        padding: 0;
        cursor: pointer;
        color: #ffffff;
        background-color: transparent;
        display: flex;
        align-items: center;
        transition: all .3s ease;

        &:hover{
          transform: scale(1.08);
          box-shadow: 1.91px 9.833px 15px 0px rgba(38, 38, 38, 0.15);
        }

        .market-img-div {
          width: 100%;
          // margin-right: 10px;

          .play-store {
            width: 160px;
            height: 58px;
          }
  
          .app-store {
            width: 175px;
            height: 58px;
          }
        }

        .market-content-div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 60%;

          p {
            margin-bottom: 0;
          }

          .tagline {
            margin-bottom: 0;
            color: #854cd2;
            font-weight: 500;
          }
          .headline {
            font-weight: 600;
            color: #333;
            font-size: 1rem;
          }
        }
      }
    }
    .lang-para{
      .para-desc{
        color: #FFFFFF;
        background-color: #fba823;
        width: fit-content;
        padding: .25rem .5rem;
        border-radius: 8px;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: $poppins;
      }
    }
  }

  .banner-img-fluid {
    width: 90%;
    height: 90%;
  }
}
