// Font Family
$poppins: "Poppins", sans-serif;
$abrilfatface: "Abril Fatface", cursive;
$cormorantgaramond: "Cormorant Garamond";
$greatvibes: "Great Vibes", cursive;
$lobster: "Lobster", cursive;
$josefin: "Josefin Sans", sans-serif;

// text Colors ---------------

$white: #ffffff;
$black: #1f1f1f;
$blue: #1f294c;
$grey: #6d6e7b;
$red: #D90404;
$green: #008060;
$navy-blue-shade: #2c3e50;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;
$bg-red: #D90404;
$bg-grey-shade-1: #ccc;
$bg-navy-blue-shade: #2c3e50;

// Heading Color
$heading-color: #000000;

// Theme Color
// $theme-color: #f77949;

$theme-color: #A32BB4; //#00044bcb
$theme-secondary-color: #854CD2;

// Hover Color
$theme-hover-color: #ea1f00f5;


// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout-1500: "only screen and (max-width: 1500px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$lg-md-layout: "only screen and (max-width: 1024px)";
$lg-med-layout: "only screen and (max-width: 991px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 992px)";
$xs-layout: "only screen and (max-width: 767px)";
$xs-med-layout: "only screen and (max-width: 576px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
$xxs-md-layout: "only screen and (max-width: 425px)";