.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid $theme-secondary-color;
  border-radius: 4px;
  background: $theme-secondary-color;
  color: white;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 0.5s;

  &:hover,
  &:active {
    background: $theme-hover-color;
    border-color: $theme-hover-color;
    color: #fff;
  }

  &:focus {
    outline: none;
  }
}

.button--inverse {
  background: transparent;
  border: 2px solid $theme-secondary-color;
  color: $theme-color;
  height: 100%;
  padding: 0.6rem 1.5rem;

  &:hover,
  &:active {
    color: white;
    background: $theme-color;
  }
}

.button--google,
.button--facebook {
  width: 90%;
  margin: 0.1rem 0.5rem;
  color: #fff;
  font-size: 12px;
  font-family: "Poppins";
  letter-spacing: 0.71px;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
}

.button--facebook:hover {
  background: #3b5998;
}
.button--google:hover {
  background: #de403a;
}
.button--google {
  background: #de403a;
}

.button--facebook {
  background: #3b5998;
}

.button--big {
  width: 100%;
  font-size: 15px;
  padding: 0.5rem 0;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}


@keyframes moveCircle {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

.btn,
.btn:link,
.btn:visited {
  padding: 1rem;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  font-size: 2.6rem;
  border: none;
  
  cursor: pointer;
  animation-name: moveCircle;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  color: #fff;
}
.btn:hover::after {
  transform: scale(1.8);
  opacity: 0;
}

.btn:active,
.btn:focus {
  outline: none;
  transform: translateY(1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn--white {
  background-color: rgba(19, 28, 33,.7);
  color: #fff;
  // padding: 0.1rem 0.2rem;
}
.btn--white::after {
  background-color: $theme-color;
}


.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

// .btn--animated {
//   animation: moveInBottom 0.5s ease-out 0.75s;
//   animation-fill-mode: backwards;
// }



