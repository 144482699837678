.App {
    width: 50%;
    margin: 50px auto;
    text-align: center;
}



.card {
    border: 2px solid rgb(250, 248, 149);
    border-radius: 20px;
    /* box-shadow: 2px 2px 2px 2px rgb(245, 243, 200); */
    overflow: hidden;
    height: 200px;
    
    width: auto;
    color: #fff;
    padding: 5%;
    margin-top: 20%;
}

.cardtop h3 {
    font-size: 0.8rem;
    margin: 20px;
    line-height: 1.3;
    text-align: center;
}

.cardtop>img {
    margin-top: 15%;
    width: 30%;
}

@media (max-width:1300px) {
    .card{
        height:160px;
    }
}

@media (max-width: 800px) {
    .card {
        height: 200px;
        padding: 10% 8%;
    }
}

/* .slick-slide {
    margin: 0 20px;
  } */
 /* .slick-slide{
    margin-left:  15px;
    margin-right:  15px;
  } */

  /* .slick-list {
    margin-left: -15px;
    margin-right: -15px;
    pointer-events: none;
  }  */