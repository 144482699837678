
.banner {
    background-image: url("../assets/images/about/aboutimg.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
}

.bannertext {
    width: 40%;
    margin-left: 15%;
    margin-bottom: 10%;
}

.row {
    width: 85%;
    margin: 100px auto 100px auto;
    text-align: left;
    line-height: 1.6;
}

.ceoimage {
    border-radius: 3%;
    width: 50%;
    float: right;
    margin: auto 0% auto 2%;

    margin-top: 6.5px;
}

/* first card */
.panaimage {
    border-radius: 3%;
    height: 450px;
    padding: 1%;
    width: 50%;
    float: right;
    margin: auto 0% auto 2%;
}

.cards {
    display: table-cell;
    width: auto;
    height: 450px;
    vertical-align: middle;
    text-align: left;
    padding-left: 10%;
}

.cardsrow {
    width: 85%;
    margin: 150px auto;
    text-align: left;
    line-height: 1.6;
    border: 2px solid rgb(250, 248, 149);
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px rgb(245, 243, 200);
}

/* second card */
.rafikiimage {
    border-radius: 3%;
    height: 450px;
    padding: 1%;
    width: 50%;
    float: left;
    margin: auto 0% auto 2%;
}

.rcards {
    display: table-cell;
    width: auto;
    height: 450px;
    vertical-align: middle;
    text-align: left;
    padding-right: 10%;
}

.rcardsrow {
    width: 85%;
    margin: 20px auto;
    text-align: right;
    line-height: 1.6;
    border: 2px solid rgb(250, 248, 149);
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px rgb(245, 243, 200);
}

/* carousol */

.caro {
    margin: auto 10%;

}

.carod {
    border: 2px solid black;
}


/* more about us  */

.mauparent {
    display: inline-block;
    margin-left: 15%;
}

.line {
    width: 70%;
    margin: 0.5% 15%;
    background-color: #F9AA4D;
}

.mauimage {
    height: auto;
    width: 5%;
    float: left;
    margin: 0.7% 5%;
}

.mcards {
    display: table-cell;
    text-align: left;
    padding-right: 10%;

}

.mcardsrow {
    width: 800px;
    margin: 0 auto;
    text-align: right;
    line-height: 1.6;
}

/* yt parent */

.ytparent {
    width: 90%;
    margin: 5% 5% 5% 0%;
}

.ytchild {
    width: 55%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    float: left;
    margin-right: 5%;
}

.yttext {
    height: 430px;
    margin: 0;
    display: table-cell;
    font-size: 46px;
    vertical-align: middle;
    text-align: left;
    line-height: 1.6;
}


@media (max-width: 800px) {
    .bannertext {
        width: 100%;
        text-align: center;
        margin: 0% 10% 20% 10%;

    }

    .row {
        width: 85%;
        margin: 100px auto 100px auto;
        text-align: left;
        line-height: 1.7;
    }

    .ceoimage {
        border-radius: 3%;
        width: 100%;
        float: top;
        margin: 2% 0%;
        margin-top: 6.5px;
    }

    .cardsrow {
        width: 85%;
        margin: 100px auto;
        text-align: left;
        line-height: 1.6;
        overflow: hidden;
        /* margin-bottom: 5rem; */
    }

    .panaimage {
        border-radius: 3%;
        width: 100%;
        height: auto;
        float: bottom;
        margin: 2% 0%;
        margin-top: 6.5px;
    }

    .h1 {

        font: cookies;
        display: flex;
        align-self: center;
    }

    .cards {
        text-align: center;
        padding: 5%;
    }

    /* second card */
    .rcardsrow {
        width: 85%;
        margin: 100px auto;
        text-align: left;
        line-height: 1.6;
        overflow: hidden;
        /* margin-bottom: 5rem; */
    }

    .rafikiimage {
        border-radius: 3%;
        width: 100%;
        height: auto;
        float: bottom;
        margin: 2% 0%;
        margin-top: 6.5px;
    }

    .rcards {
        text-align: center;
        padding: 5%;
    }

    .ytparent {
        width: 90%;
        margin: 10%;

    }

    .ytchild {
        width: 90%;
        margin: 10% 5% 0% 5%;
        border-radius: 30px;
        float: top;
    }

    .yttext {
        text-align: center;
    }
}


@media (max-width: 1028px) {
    .mcardsrow {
        width: 90%;
    }

    .mauparent {
        margin: 0%;
    }

    .line {
        width: 80%;
        margin: 2% 10%;
    }

    .mauimage {
        width: 35px;
    }

    .ytparent {
        width: 90%;
        margin: 5%;
    }

    .ytchild {
        height: 300px;

    }

    .yttext {
        height: 300px;
        text-align: center;
        font-size: 40px;
    }

}
