.community {
  padding: 75px 0;
  background-image: linear-gradient(
      to right,
      rgba(68, 68, 68, 0.38),
      rgba(68, 68, 68, 0.38)
    ),
    url(/static/media/homebg.10ba5321.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;

  .comm-left{
    .banner-heading{
      color: #FFFFFF;
      font-weight: 600;
    }
  }

  .comm-right {
    .enquiry {
      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 0.25rem;
      }
      .form-control {
        margin: 1rem 0 0;
        border-radius: 4px;
        height: 45px;
      }
    }
  }
}
