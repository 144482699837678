.no-data {
    text-align: center;
    padding: 10px 0;

    img {
        max-width: 300px;
    }

    p {
        font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    }
}