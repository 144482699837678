.brands {
  padding: 25px 0;
  // border: 1px solid #efefef;
  border-left: none;
  border-right: none;
  // margin-bottom: 2rem;

  img {
    width: 70%;
    height: 70%;
    margin:0% 15%;
    filter: drop-shadow(8px, 8px, 4px #000);
  }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
