.main {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 2% 10% 2% 10%;

}

.bbutton{
    display: block;
    color: #ffffff;
    width: max-content;
    /* margin-top: 1.5rem; */
    font-size: 1rem;
    letter-spacing: 1.1px;
    font-weight: 500;
    /* // text-transform: uppercase; */
    padding: 10px 22px;
    border-radius: 30px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: transparent;
    background-image: linear-gradient(90deg, #A32BB4 0%, #854CD2 100%);
}

.bottombanner{
  
    height: auto;
    width: 100%;
    
}
.text {
    margin: 5% 1% 0% 0%;
    /* width:50%; */
    font-family: cookies;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
}



.intro {
    margin: 2% 0%;
    font-family: cookies;
    font-size: 36px;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
}

.banner{
    width:90%;
    margin-top: 5%;
    padding-left: 2%;
    line-height: 1.2;

}

.book {
    width: 35%;
    height: auto;
}

.booksp {
    display: none;
    width: 40%;
}
.bbanner{
    align-items: center;
    justify-content: center;
    margin: 40px ;
}

.book2 {
    width: 40%;
    height: auto;
}

.book21 {
    display: none;
}

.cards {
    width: 90%;
    margin: 5% auto;
    display: flex;
    justify-content: center;
}

.can {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
}

.container {

    width: 300px;
    border-radius: 20px;
    position: relative;
    box-shadow: 1px 1px 20px lightgray;
    padding: 20px;
    /* text-align: center; */

}




.container p {
    font-family: cookies;
    font-size: 18px;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: white;

}

.container button {
    padding: 4px;
    margin: 10px;
    width: 90px;
    font-size: 12px;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    border-radius: 12px;
    color: white
}

.container a {
    padding: 0;
    margin: 0;
    color: #fff;
}

.container img {
    width: 80%;
    margin-top: 5% ;
    object-fit: cover;
}

.can :hover {
    transform: scale(1.1);
}

.features {
    /* text-align: center; */
    justify-content: center;

}

.features img {
    width: 15%;
}

.intro{
    margin-top: 15%;
}




@media (max-width:800px) {
    .main {
        justify-content: center;
    }

    .book21 {
        display: block;
        width: 60%;
        margin: 8% 20% 0% 20%;

    }

    .text {
        text-align: center;
    }
     
    .banner{
        font-size: 18px;
        line-height: 1.2;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .bbanner{
        margin: 0;
        font-size: 12px;
        padding: 5px;
    }

    .can :hover {
        transform: scale(1.1);
    }

    .text h1 {
        font-size: 18px;


    }

    .booksp {
        display: block;
        width: 40%;
        margin: 0% 30%;

    }

    .text h4 {
        font-size: 12px;
        line-height: 1.2;
    }

    .book {
        display: none;
    }
    .features img {
        width: 30%;
    }
    

    .intro {
        text-align: center;
        margin-top: 2%;
        font-size: 18px;
    }

    .main h4 {
        font-size: 12px;
        line-height: 1.2;
    }

    .book2 {
        display: none;
    }

    .features h5 {
        font-size: 14px;
        width: 100%;
        height: auto;
        display: block;
        margin: 8px 0px;
        border: 2px solid #c8a5f7;
    }

    h6 {
        font-size: 10px;
    }

    .cards {
        margin: 40px auto;
    }
}