.about-page {
  .about-banner {
    background-image: url("../images/about/aboutimg.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;

    .about-banner-content {
      h1 {
        color: #ffffff;
        font-size: 42px;

        @media #{$xs-layout} {
            font-size: 35px;
        }
      }
    }
  }

  .about-quality{
    h2{
      color: #B555C3;  
      line-height: 36px;
    }

    p{
        font-size: 16px;
    }

    a{
        padding: 0;
    }
  }

  .about-values-card {
    height: 510px;

    h2{
        font-size: 26px;
        color: #a32bb4;
        letter-spacing: 0.1rem;
        line-height: 1.95rem;
        margin-bottom: 1rem;
    }
  }

  .about-card {
    border: 2px solid rgb(250, 248, 149);
    border-radius: 10px;
    padding: 16px;
    margin: 1rem 0;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 230px;

    &:hover {
      transform: scale(1.1);
    }

    .about-card-heading {
      display: flex;
      align-items: flex-end;
      gap: 16px;
      margin-bottom: 0.5rem;

      .about-card-img {
        width: 70px;
        height: 70px;
      }

      h2 {
        font-size: 23px;
        color: #a32bb4;
        letter-spacing: 0.1rem;
        line-height: 1.5rem;
        margin-bottom: 0;
      }
    
    }
    // .about-card-content {
    //   p {
    //     font-size: 18px;
    //     color: #673117;
    //     line-height: 1.6rem;
    //     text-align: center;
    //     margin-bottom: 0;
    //   }
    // }
  }
  .youtube-embed {
    border-radius: 10px;
  }
}

.youtube-card{
  height: 525px;
  cursor: pointer;
  transition: all .3s ease-in-out 0s;

  &:hover{
    box-shadow: 5px 7px 8px rgb(0 0 0 / 25%),
    0 -1px 1px rgb(0 0 0 / 13%);

  }
}

.youtube-review-text{
  color: #A32BB4;
  // font-family: cookies;
  font-size: 19px;
  font-weight: 700;
}